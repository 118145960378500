import { InferType, boolean, number, object, string } from 'yup';
import { Nullable } from '../../../model/common';

export const settingsSchema = object({
    general: object({
        timezone: object({ label: string().required(), value: string() }).required('Timezone Settings is required.'),
        idleTimeout: object({ label: string().required(), value: number() }).required('Idle Timeout is required.')
        // duration: object({ label: string().required(), value: number() }).required('Notification Duration is required.')
    }),
    notifications: object({
        show: object({ label: string().required(), value: boolean() }).required('Notification Visibility is required.')
        // duration: object({ label: string().required(), value: number() }).required('Notification Duration is required.')
    }),
    trading: object({
        tradingLayout: object({ label: string().required(), value: string().required() }).required(
            'Trading Layout is required.'
        ),
        twoClickTrading: object({ label: string().required(), value: boolean().required() }).required(
            'Two-Click Trading is required.'
        ),
        shortSellPrevention: object({ label: string().required(), value: boolean().required() }).required(
            'Short Sell Prevention is required.'
        ),
        defaultMarketOrderDuration: object({ label: string().required(), value: number().required() }).required(
            'Default Market Order Duration is required.'
        ),
        defaultLimitOrderDuration: object({ label: string().required(), value: number().required() }).required(
            'Default Limit Order Duration is required.'
        )
    })
});

export type SettingsFormValues = InferType<typeof settingsSchema>;
export type SettingsFormInput = Nullable<SettingsFormValues>;
