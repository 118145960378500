import { Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router';
import LiteLayout from './components/layout/lite/LiteLayout';
import IdleModal from './components/modal/IdleModal';
import { useAppDispatch, useAppSelector } from './state/hooks';
import { controlInitSubscriptions, selectCredentials } from './state/reducers/authSlice';
import { useDisclosure } from './utils/hooks/useDisclosure';
import { useIdle } from './utils/hooks/useIdle';

import LiteSidebar from './components/layout/lite/LiteSidebar';
import LiteBalances from './components/lite-modules/LiteBalances';
import LiteMarkets from './components/lite-modules/LiteMarkets';
import { fetchUserMarkets } from './state/reducers/marketPairSlice';
import { selectGeneralSettings } from './state/reducers/settingSlice';
import { isCorrectLayout } from './utils/route';

const xplorspotliteEnabled = window.config?.layout?.xplorspotlite?.enabled;

function XplorSpotLite() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const settings = useAppSelector(selectGeneralSettings);
    const credentials = useAppSelector(selectCredentials);

    const idle = useIdle(settings.idleTimeout.value);
    const idleDisclosure = useDisclosure(false);

    useEffect(() => {
        if (idle) idleDisclosure[1].open();
    }, [idle]);

    useEffect(() => {
        if (!credentials) {
            navigate('/login');
        } else {
            if (isCorrectLayout('xplorspotlite')) {
                if (xplorspotliteEnabled) {
                    // Fire initial requests
                    dispatch(controlInitSubscriptions());
                    dispatch(fetchUserMarkets());
                } else {
                    navigate('/');
                }
            } else {
                navigate('/trader');
            }
        }
    }, []);

    return credentials ? (
        <Fragment>
            <LiteLayout>
                <div className="flex w-full h-full max-w-7xl text-neutral-200 p-0 sm:px-2 lg:divide-x-[3px] lg:divide-brand-red">
                    <LiteSidebar />
                    <div className="flex flex-col h-full w-full p-2 sm:p-6 pb-0 gap-3">
                        {/* <h1 className="font-bold md:text-xl">Good morning</h1> */}
                        <div className="flex h-auto md:h-full w-full gap-2 sm:gap-6 flex-col-reverse md:flex-row">
                            <LiteBalances />
                            <LiteMarkets />
                        </div>
                    </div>
                </div>
                <IdleModal opened={idleDisclosure[0]} handlers={idleDisclosure[1]} />
            </LiteLayout>
        </Fragment>
    ) : null;
}

export default XplorSpotLite;
