import { doLogout } from '@/state/reducers/authSlice';

import DepositRequestModal from '@/components/modal/WithdrawalModal/DepositRequestModal';
import InternalTransferRequestModal from '@/components/modal/WithdrawalModal/InternalTransferRequestModal';
import WithdrawalRequestModal from '@/components/modal/WithdrawalModal/WithdrawalRequestModal';
import { changeBalanceAccount } from '@/state/reducers/balanceSlice';
import { getEnvironment } from '@/helpers/environmentHelper';
import history from '@/utils/history';
import { useDisclosure } from '@/utils/hooks/useDisclosure';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import EnvironmentBadge from '../../common/EnvironmentBadge';
import SettingsModal from '../../modal/SettingsModal';
import MobileDrawer from '../MobileDrawer';
import LiteDesktopNavbar from './LiteDesktopNavbar';
import LiteMobileNavbar from './LiteMobileNavbar';

const depositEnabled = window.config.modules.deposit.enabled;
const withdrawalEnabled = window.config.modules.withdrawal.enabled;

export const onLogout = async (credentials, dispatch) => {
    try {
        if (credentials) await dispatch(doLogout({ credentials }) as any).unwrap();
    } catch (rejectedValueOrSerializedError) {
        //
    }
    history.push('/login');
};

export const onUserChange = async (credentials, dispatch) => {
    if (credentials && getEnvironment() !== 'prod') await dispatch(changeBalanceAccount({ credentials }));
};

export default function LiteNavbar() {
    const deposit = useDisclosure(false);
    const withdrawal = useDisclosure(false);
    const internalTransfer = useDisclosure(false);
    const settings = useDisclosure(false);
    const drawer = useDisclosure(false);

    const navigate = useNavigate();

    return (
        <Fragment>
            <div className="flex max-w-7xl w-full justify-between items-end relative h-12 gap-3 sm:px-6">
                <div
                    className="flex flex-row h-full cursor-pointer items-center xs:items-end gap-3"
                    onClick={() => navigate('/lite')}>
                    <img className="block h-10 xs:h-12" src={window.config?.lite_logo} alt="Mohicans Markets" />
                    <EnvironmentBadge />
                </div>

                <LiteMobileNavbar drawerDisclosure={drawer} />
                <LiteDesktopNavbar
                    depositDisclosure={deposit}
                    withdrawalDisclosure={withdrawal}
                    internalTransferDisclosure={internalTransfer}
                    settingsDisclosure={settings}
                />
            </div>
            <MobileDrawer
                depositDisclosure={deposit}
                withdrawalDisclosure={withdrawal}
                internalTransferDisclosure={internalTransfer}
                settingsDisclosure={settings}
                drawerDisclosure={drawer}
            />
            {depositEnabled && <DepositRequestModal opened={deposit[0]} handlers={deposit[1]} />}
            {withdrawalEnabled && <WithdrawalRequestModal opened={withdrawal[0]} handlers={withdrawal[1]} />}
            <InternalTransferRequestModal opened={internalTransfer[0]} handlers={internalTransfer[1]} />
            <SettingsModal opened={settings[0]} handlers={settings[1]} />
        </Fragment>
    );
}
