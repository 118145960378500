import { convertedQtyFormatterConfig } from '@/config/config';
import { mergeRefs, parseShortFormNumber } from '@/utils/common';
import BigNumber from 'bignumber.js';
import cn from 'classnames';
import { ComponentProps, forwardRef, useEffect, useRef } from 'react';
import { useLocale, useNumberField } from 'react-aria';
import { useFormContext } from 'react-hook-form';
import { useNumberFieldState } from 'react-stately';

// to be deprecated

interface Props extends ComponentProps<'input'> {
    name: string;
    label?: string;
    min?: string | number;
    step?: number;
    className?: any;
}

const RHFNumberInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
    const { name, label, min = 0, step = 1, disabled, className } = props;
    const { getFieldState, getValues, formState, setValue, watch } = useFormContext();
    const fieldState = getFieldState(name, formState);

    const inputRef = useRef(null);
    const { locale } = useLocale();

    const state = useNumberFieldState({ locale, formatOptions: convertedQtyFormatterConfig });
    const { labelProps, groupProps, inputProps } = useNumberField({ label }, state, inputRef);

    const value = watch(name);

    useEffect(() => {
        if (value !== state.numberValue && !isNaN(state.numberValue)) {
            setValue(name, state.numberValue, { shouldValidate: true, shouldDirty: true });
        } else if (isNaN(state.numberValue)) {
            setValue(name, null);
        }
    }, [state.numberValue]);

    useEffect(() => {
        if (value !== state.numberValue) {
            state.setNumberValue(value);
        }
    }, [value]);

    return (
        <div className="w-full gap-1 flex flex-col">
            {label && (
                <label {...labelProps} className="block text-sm font-medium text-neutral-200">
                    {label}
                </label>
            )}
            <div {...groupProps} className="relative flex">
                <input
                    {...inputProps}
                    ref={mergeRefs(inputRef, ref)}
                    value={state.inputValue}
                    placeholder={props.placeholder}
                    onKeyDown={(e) => {
                        if (step >= 100_000 && ['k', 'm'].includes(e.key)) {
                            e.preventDefault();
                            const val = parseShortFormNumber(state.numberValue, e.key);
                            if (!isNaN(val)) {
                                setValue(name, val, { shouldValidate: true, shouldDirty: true });
                                state.commit();
                            }
                        }
                    }}
                    autoComplete="off"
                    className={cn(
                        'appearance-none block w-full px-3 py-2 bg-brand-background-dark border border-neutral-700 rounded-md shadow-sm text-sm placeholder-neutral-500 focus:outline-none focus:ring-brand-red focus:border-brand-red',
                        { 'text-neutral-400 cursor-not-allowed': disabled },
                        { 'text-neutral-200': !className },
                        className
                    )}
                    disabled={disabled}
                />
                <div className="absolute right-2 flex flex-col h-full justify-center text-xs text-neutral-200 w-5">
                    <button
                        type="button"
                        className={cn('px-0', {
                            'text-neutral-400 cursor-not-allowed': disabled,
                            'hover:text-brand-red-light': !disabled
                        })}
                        onClick={() => {
                            if (!disabled) {
                                const prevValue = +(getValues(name) || 0);
                                const nextValue = BigNumber(prevValue).plus(step).toNumber();
                                // state.setNumberValue(nextValue);
                                setValue(name, nextValue, { shouldValidate: true });
                            }
                        }}>
                        &#x25B2;
                    </button>
                    <button
                        type="button"
                        className={cn('px-0', {
                            'text-neutral-400 cursor-not-allowed': disabled,
                            'hover:text-brand-red-light': !disabled
                        })}
                        onClick={() => {
                            if (!disabled) {
                                const prevValue = +(getValues(name) || 0);
                                const nextValue = BigNumber(prevValue).minus(step).toNumber();
                                if (nextValue <= +min) setValue(name, min, { shouldValidate: true });
                                // if (nextValue <= +min) state.setNumberValue(+min);
                                // else state.setNumberValue(nextValue);
                                else setValue(name, nextValue, { shouldValidate: true });
                            }
                        }}>
                        &#x25BC;
                    </button>
                </div>
            </div>
            {fieldState.error ? <span className="text-brand-red italic">{fieldState.error?.message}</span> : null}
        </div>
    );
});

RHFNumberInput.displayName = 'RHFNumberInput';

export default RHFNumberInput;
