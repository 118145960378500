import { useEffect, useMemo } from 'react';

import { Side } from '@/compiled_proto/com/celertech/marketdata/api/enums/SideProto';
import { BlotterItem } from '@/model/blotters';
import { amendOrder } from '@/services/OrderService';
import { selectCredentials } from '@/state/reducers/authSlice';
import { useCurrency } from '@/utils/hooks/useCurrency';
import { useInstrument } from '@/utils/hooks/useInstrument';
import useOrderBook, { invalidLimitPriceTooltip } from '@/utils/hooks/useOrderBook';
import { convertToPips } from '@/utils/slippage';
import { yupResolver } from '@hookform/resolvers/yup';
import BigNumber from 'bignumber.js';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Button } from '../common/Button';
import Divider from '../common/Divider';
import Tooltip from '../common/Tooltip';
import { AmendOrderFormInput, amendOrderSchema } from '../form/schema/amendOrderSchema';
import Input from '../inputs/Input';
import InputController from '../inputs/InputController';
import NumberInput from '../inputs/NumberInput';
import NumberInputDisplay from '../inputs/NumberInputDisplay';
import Modal, { ModalClose, ModalHeader, ModalProps, ModalTitle } from './Modal';

interface AmendOrderModalProps extends ModalProps {
    order: BlotterItem;
    onCancelOrder: () => void;
}

const defaultValues: Partial<AmendOrderFormInput> = {
    pair: '',
    side: '',
    ccy: '',
    orderType: '',
    orderId: '',
    status: '',
    duration: '',
    price: 0,
    quantity: 0
};

const AmendOrderModal = (props: AmendOrderModalProps) => {
    const { order, opened, handlers, onCancelOrder } = props;
    const { orderType, pair, slippage, side } = order;

    const credentials = useSelector(selectCredentials);

    const { isLimit, isStopMarket, isStopLimit } = useMemo(
        () => ({
            isLimit: orderType === 'LIMIT',
            isStopMarket: orderType === 'STOP_MARKET',
            isStopLimit: orderType === 'STOP_LIMIT'
        }),
        [orderType]
    );

    const slip = useMemo(() => +(slippage || 0), [slippage]);
    const [baseCurrencyCeler] = useMemo(() => (pair || '').split('/'), [pair]);
    const ccy2Order = useMemo(() => order.ccy === order.ccy2, [order]);

    const { bestAsk, bestBid } = useOrderBook(pair, Side[side]);
    const { min_order_size, order_decimals, increments } = useCurrency(baseCurrencyCeler);
    const { pip_size, formatPrice } = useInstrument(pair || '');

    const form = useForm<AmendOrderFormInput>({
        defaultValues,
        mode: 'onChange',
        resolver: yupResolver(
            amendOrderSchema({
                side,
                orderType,
                bestAsk,
                bestBid,
                formattedBestAsk: formatPrice(bestAsk),
                formattedBestBid: formatPrice(bestBid),
                ccy2Order,
                order_decimals,
                min_order_size,
                currencyPair: pair,
                currencyOut: baseCurrencyCeler
            })
        )
    });

    const {
        reset,
        watch,
        handleSubmit,
        formState: { isDirty, isSubmitting, isValid }
    } = form;

    const onSubmit = async (data: AmendOrderFormInput, e) => {
        const { quantity, price } = data as AmendOrderFormInput;

        if (order && credentials) {
            const payload = {
                orderId: order.orderId,
                clOrdId: order.celerClOrderId,
                origClOrdId: order.originalCelerClOrderId,
                account: order.account,
                // amended fields
                qty: quantity || 0
            };
            if (isLimit) {
                payload['price'] = price;
            } else if (isStopMarket) {
                payload['stopPrice'] = price;
            } else if (isStopLimit) {
                payload['stopPrice'] = price;
                payload['price'] = stopLimitPrice;
            }
            amendOrder(payload, credentials);
            handlers.close();
        }
    };

    const price = watch('price');

    const stopLimitPrice = useMemo(() => {
        const stop = +(price || 0);
        if (Side[side] === Side.BUY) return BigNumber(stop).plus(slip).toNumber();
        else if (Side[side] === Side.SELL) return BigNumber(stop).minus(slip).toNumber();
        else return stop;
    }, [price]);

    const orderPriceType = useMemo(() => (isStopMarket || isStopLimit ? 'Stop Price' : 'Limit Price'), [orderType]);

    const limitPriceTooltip = useMemo(() => {
        if (price && isLimit) {
            if (side === 'BUY') {
                if (ccy2Order && +price < bestBid) {
                    return invalidLimitPriceTooltip(Side[side], bestBid, ccy2Order);
                } else if (!ccy2Order && +price > bestAsk) {
                    return invalidLimitPriceTooltip(Side[side], bestAsk, ccy2Order);
                }
            }
            if (side === 'SELL') {
                if (ccy2Order && +price > bestAsk) {
                    return invalidLimitPriceTooltip(Side[side], bestAsk, ccy2Order);
                } else if (!ccy2Order && +price < bestBid) {
                    return invalidLimitPriceTooltip(Side[side], bestBid, ccy2Order);
                }
            }
            return '';
        }
    }, [price, isLimit, side, bestAsk, bestBid]);

    useEffect(() => {
        if (opened) {
            reset({
                pair: order.pair,
                side: order.side,
                ccy: order.ccy,
                orderType: order.orderType,
                orderId: order.orderId,
                status: order.status,
                duration: order.tif,
                price: isStopMarket || isStopLimit ? order.stopPrice : order.price,
                quantity: order.qty
            });
        }
    }, [opened, order]);

    // useEffect(() => {
    //     async function getLatestOrderBook() {
    //         if (credentials) upgradeMarket(credentials, pair, dispatch);
    //     }
    //     if (opened) getLatestOrderBook();
    // }, [opened, order]);

    return (
        <Modal {...props} className="lg:h-auto" size="max-w-xl">
            <FormProvider {...form}>
                <form className="h-full flex flex-col lg:block" onSubmit={handleSubmit(onSubmit)}>
                    <ModalHeader>
                        <ModalTitle>Amend Order</ModalTitle>
                        <ModalClose handlers={handlers} />
                    </ModalHeader>
                    <Divider />
                    <div className="flex-1 basis-0 lg:flex-auto p-2 py-3 sm:p-4 h-full lg:h-auto overflow-y-scroll w-full text-neutral-200">
                        <div className="space-y-4 overflow-y-auto">
                            <InputController name="pair" label="Symbol Name" disabled>
                                <Input />
                            </InputController>

                            <InputController name="side" label="Side" disabled>
                                <Input />
                            </InputController>

                            <InputController name="ccy" label="Currency" disabled>
                                <Input />
                            </InputController>

                            <InputController name="orderType" label="Order Type" disabled>
                                <Input />
                            </InputController>

                            <InputController name="orderId" label="Order Id" disabled>
                                <Input />
                            </InputController>

                            <InputController name="status" label="Status" disabled>
                                <Input />
                            </InputController>

                            <InputController name="duration" label="Duration" disabled>
                                <Input />
                            </InputController>

                            <Tooltip content={limitPriceTooltip || ''} className="-top-14 right-0 w-2/3">
                                <InputController
                                    name="price"
                                    label={orderPriceType}
                                    className={{
                                        'text-brand-red': limitPriceTooltip,
                                        'text-neutral-200': !limitPriceTooltip
                                    }}>
                                    <NumberInput
                                        placeholder={orderPriceType}
                                        step={BigNumber(1)
                                            .dividedBy(Math.pow(10, pip_size || 0))
                                            .toNumber()}
                                    />
                                </InputController>
                            </Tooltip>

                            <InputController name="quantity" label="Quantity">
                                <NumberInput placeholder="Quantity" min={min_order_size} step={increments} />
                            </InputController>

                            {isStopLimit && (
                                <InputController label="Slippage (pips)" value={convertToPips(slip, pip_size)}>
                                    <NumberInputDisplay placeholder="Slippage (pips)" disabled />
                                </InputController>
                            )}

                            {isStopLimit && (
                                <InputController label="Stop Limit Price" value={stopLimitPrice}>
                                    <NumberInputDisplay placeholder="Stop Limit Price" disabled />
                                </InputController>
                            )}
                        </div>
                    </div>
                    <Divider />
                    <div className="flex flex-row justify-between text-neutral-200 text-sm items-center gap-2 p-2 sm:p-4">
                        <button
                            type="button"
                            className="rounded-md p-2 px-4 bg-neutral-600 hover:bg-neutral-500"
                            onClick={handlers.close}>
                            Back
                        </button>
                        <div className="flex gap-2">
                            <Button
                                type="button"
                                className="rounded-md p-2 px-4 focus:ring-brand-red-light bg-brand-red hover:bg-brand-red-light w-auto"
                                onClick={onCancelOrder}>
                                Cancel Order
                            </Button>
                            <Button
                                type="submit"
                                className="rounded-md p-2 px-4 bg-brand-primary hover:bg-brand-primary-light w-auto"
                                isLoading={isSubmitting}
                                disabled={!isDirty || !isValid}>
                                Save Changes
                            </Button>
                        </div>
                    </div>
                </form>
            </FormProvider>
        </Modal>
    );
};

export default AmendOrderModal;
