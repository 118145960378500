import { Fragment, useEffect, useMemo, useState } from 'react';

import { Side } from '@/compiled_proto/com/celertech/marketdata/api/enums/SideProto';
import { Button } from '@/components/common/Button';
import { ConvertOrderFormInput, convertOrderSchema } from '@/components/form/schema/convertSchema';
import { CurrencyOption, currencyOptions } from '@/helpers/currencyHelper';
import { Modify } from '@/model/common';
import { useAppSelector } from '@/state/hooks';
import { selectAggregatedBalance } from '@/state/reducers/balanceSlice';
import { selectShortSellPrevention } from '@/state/reducers/settingSlice';
import { store } from '@/state/store';
import { getCurrency, useCurrency } from '@/utils/hooks/useCurrency';
import { useDisclosure } from '@/utils/hooks/useDisclosure';
import { Tab } from '@headlessui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import BigNumber from 'bignumber.js';
import cn from 'classnames';
import { FormProvider, useForm } from 'react-hook-form';
import Modal, { ModalProps } from '../Modal';
import ConvertConfirmationModal from './ConvertConfirmationModal';
import { convertToRate } from './ConvertFrom';
import ConvertTab, { alternativeFrom, alternativeTo, defaultFrom, defaultTo } from './ConvertTab';

const tabs = ['Convert'];

const availableOption = (options: CurrencyOption[], name: string) => options.find((ccy) => ccy.value === name);

const defaultValues: Partial<ConvertOrderFormInput> = {
    pair: '',
    bestPrice: undefined,
    convertFrom: defaultFrom,
    convertTo: defaultTo,
    amount: undefined,
    estimatedReturn: undefined,
    side: Side.BUY
};

const LiteOrderModal = (props: Modify<ModalProps, { initFrom?: string; initTo?: string }>) => {
    const { opened, handlers, initFrom, initTo } = props;

    const celerBalance = useAppSelector(selectAggregatedBalance);
    const shortSellPrevention = useAppSelector(selectShortSellPrevention);

    const confirmationDisclosure = useDisclosure(false);
    const convertToDisclosure = useDisclosure(false);

    const [convertFromState, setConvertFromState] = useState<CurrencyOption>(defaultFrom);
    const { min_order_size, order_decimals } = useCurrency(convertFromState.value);

    const balances = useMemo(() => {
        return celerBalance;
    }, [celerBalance]);

    const form = useForm<ConvertOrderFormInput>({
        defaultValues,
        mode: 'onChange',
        resolver: yupResolver(
            convertOrderSchema(
                {
                    currencyPair: convertFromState.value,
                    currencyOut: convertFromState.value,
                    order_decimals,
                    min_order_size
                },
                shortSellPrevention,
                balances
            )
        )
    });

    const { reset, watch, setValue, handleSubmit } = form;

    const [convertFrom, convertTo, amount] = watch(['convertFrom', 'convertTo', 'amount']) as any;

    const { availablePairs, availableConverts } = useMemo(() => {
        const keys = Object.keys(store.getState().marketPair.bidAsk);
        const availablePairs = keys.filter((key) => key.includes(convertFrom.value));
        const availableConverts = availablePairs
            .map((pair) => {
                const [from, to] = pair.split('/');
                const ccy = from === convertFrom.value ? to : from;
                const { show, longName } = getCurrency(ccy);
                return {
                    label: longName || ccy,
                    ccy: show.toLowerCase(),
                    value: ccy
                };
            })
            .sort((a, b) => a.label.localeCompare(b.label));
        return { availablePairs, availableConverts };
    }, [convertFrom.value, convertToDisclosure[0]]);

    const { pair, type } = useMemo(() => {
        const linear = `${convertTo.value}/${convertFrom.value}`;
        const invert = `${convertFrom.value}/${convertTo.value}`;
        // Linear means the pair is available as is, invert means the pair is available in reverse
        if (availablePairs.includes(linear)) return { pair: linear, type: 'ask' };
        else if (availablePairs.includes(invert)) return { pair: invert, type: 'bid' };
        else return { pair: '', type: '' };
    }, [availablePairs, convertFrom.value, convertTo.value]);

    const bidAsk = useAppSelector((state) => state.marketPair.bidAsk[pair]);

    const estimatedProceeds = useMemo(() => {
        let returnAmount = 0;
        if (bidAsk) {
            const rate = convertToRate(bidAsk, type);
            returnAmount = BigNumber(rate)
                .multipliedBy(+amount)
                .toNumber();
            setValue('bestPrice', bidAsk[type]);
            setValue('estimatedReturn', returnAmount);
        }
        return returnAmount;
    }, [bidAsk, type, amount]);

    const conversionProps = useMemo(
        () => ({
            availablePairs,
            availableConverts,
            estimatedProceeds,
            pair,
            type
        }),
        [availablePairs, availableConverts, estimatedProceeds, pair, type]
    );

    const onSubmit = () => confirmationDisclosure[1].open();
    const onError = (err) => console.log(err);

    useEffect(() => {
        if (opened) {
            let initialFrom: any = defaultFrom;
            let initialTo: any = defaultTo;
            if (initFrom && initTo) {
                initialFrom = availableOption(currencyOptions, initFrom);
                initialTo = availableOption(currencyOptions, initTo);
            } else {
                if (initFrom) {
                    initialFrom = availableOption(availableConverts, initFrom);
                    initialTo = alternativeTo;
                }
                if (initTo) {
                    if (initTo === 'USD') {
                        initialTo = currencyOptions.find((ccy) => ccy.value === initTo);
                        initialFrom = alternativeFrom;
                    } else {
                        initialTo = availableOption(availableConverts, initTo);
                    }
                }
            }
            const resetValues = {
                ...defaultValues,
                convertFrom: initialFrom || defaultFrom,
                convertTo: initialTo || defaultTo
            };
            setConvertFromState(initialFrom || defaultFrom);
            reset(resetValues);
        }
    }, [opened]);

    return (
        <Fragment>
            <Modal {...props} className="lg:!h-full lg:max-h-[747px]" size="max-w-sm" closeOnOverlay>
                <FormProvider {...form}>
                    <form className="h-full flex flex-col" onSubmit={handleSubmit(onSubmit, onError)}>
                        <div className="h-full flex flex-col w-full text-neutral-200">
                            <Tab.Group>
                                <Tab.List className="tab-list overflow-x-auto flex py-2 px-6">
                                    {tabs.map((tab) => (
                                        <Tab
                                            key={tab}
                                            className={({ selected }) =>
                                                cn(
                                                    'p-2 font-semibold text-sm sm:text-base leading-5 text-neutral-200 focus-visible:outline-none',
                                                    {
                                                        'border-b-[1px] border-b-brand-border': selected,
                                                        'text-neutral-400 hover:bg-white/[0.12] hover:text-neutral-200':
                                                            !selected
                                                    }
                                                )
                                            }>
                                            {tab}
                                        </Tab>
                                    ))}
                                </Tab.List>
                                <Tab.Panels className="flex-1 basis-0 overflow-y-auto px-6">
                                    <ConvertTab
                                        balances={balances}
                                        conversionProps={conversionProps}
                                        convertToDisclosure={convertToDisclosure}
                                        setConvertFrom={setConvertFromState}
                                    />
                                </Tab.Panels>
                            </Tab.Group>
                        </div>
                        <div className="flex flex-row justify-between text-neutral-200 text-sm items-center py-4 px-6">
                            <Button
                                type="submit"
                                className="w-full rounded-md p-2 px-4 bg-brand-primary hover:bg-brand-primary-light">
                                REVIEW
                            </Button>
                        </div>
                        <ConvertConfirmationModal
                            conversionProps={conversionProps}
                            opened={confirmationDisclosure[0]}
                            handlers={confirmationDisclosure[1]}
                            onClose={handlers.close}
                        />
                    </form>
                </FormProvider>
            </Modal>
        </Fragment>
    );
};

export default LiteOrderModal;
