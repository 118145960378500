export interface InstrumentConfig {
    type: 'Crypto' | 'FX' | 'Index' | 'Commodity';
    price_decimals: number;
    pip_size: number;
    ccy2_enabled: 0 | 1;
    netdania: string;
    provider: 'netdania_rtc' | 'cryptoxchange' | '';
}

type InstrumentConfigProps = Record<string, InstrumentConfig>;

const instrumentConfig: InstrumentConfigProps = {
    'ADA/USD': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'ADAUSD',
        provider: 'netdania_rtc'
    },
    'ADA/ZAR': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'ADAZAR',
        provider: 'netdania_rtc'
    },
    'ATM/USD': {
        type: 'Crypto',
        price_decimals: 4,
        pip_size: 3,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'AVE/USD': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: 'AAVEUSD',
        provider: 'cryptoxchange'
    },
    'AVX/USD': {
        type: 'Crypto',
        price_decimals: 4,
        pip_size: 3,
        ccy2_enabled: 1,
        netdania: 'AVAXUSD',
        provider: 'cryptoxchange'
    },
    'BAT/USD': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'BATUSD',
        provider: 'cryptoxchange'
    },
    'BCH/AUD': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: 'BCHAUD',
        provider: 'netdania_rtc'
    },
    'BCH/BTC': {
        type: 'Crypto',
        price_decimals: 6,
        pip_size: 4,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'BCH/EUR': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: 'BCHEUR',
        provider: 'netdania_rtc'
    },
    'BCH/GBP': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: 'BCHGBP',
        provider: 'netdania_rtc'
    },
    'BCH/JPY': {
        type: 'Crypto',
        price_decimals: 0,
        pip_size: 0,
        ccy2_enabled: 0,
        netdania: 'BCHJPY',
        provider: 'netdania_rtc'
    },
    'BCH/USC': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'BCH/USD': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: 'BCHUSD',
        provider: 'netdania_rtc'
    },
    'BCH/ZAR': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: 'BCHZAR',
        provider: 'netdania_rtc'
    },
    'BNB/USD': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: 'BNBUSD',
        provider: 'cryptoxchange'
    },
    'BTC/AUD': {
        type: 'Crypto',
        price_decimals: 1,
        pip_size: 0,
        ccy2_enabled: 0,
        netdania: 'BTCAUD',
        provider: 'netdania_rtc'
    },
    'BTC/EUR': {
        type: 'Crypto',
        price_decimals: 1,
        pip_size: 0,
        ccy2_enabled: 1,
        netdania: 'BTCEUR',
        provider: 'netdania_rtc'
    },
    'BTC/GBP': {
        type: 'Crypto',
        price_decimals: 1,
        pip_size: 0,
        ccy2_enabled: 0,
        netdania: 'BTCGBP',
        provider: 'netdania_rtc'
    },
    'BTC/JPY': {
        type: 'Crypto',
        price_decimals: 0,
        pip_size: 0,
        ccy2_enabled: 0,
        netdania: 'BTCJPY',
        provider: 'netdania_rtc'
    },
    'BTC/SGD': {
        type: 'Crypto',
        price_decimals: 1,
        pip_size: 0,
        ccy2_enabled: 1,
        netdania: 'BTCSGD',
        provider: 'netdania_rtc'
    },
    'BTC/USC': {
        type: 'Crypto',
        price_decimals: 1,
        pip_size: 0,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BTC/UST': {
        type: 'Crypto',
        price_decimals: 1,
        pip_size: 0,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BTC/USD': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 0,
        ccy2_enabled: 1,
        netdania: 'BTCUSD',
        provider: 'netdania_rtc'
    },
    'BTC/ZAR': {
        type: 'Crypto',
        price_decimals: 1,
        pip_size: 0,
        ccy2_enabled: 1,
        netdania: 'BTCZAR',
        provider: 'netdania_rtc'
    },
    'BUS/USD': {
        type: 'Crypto',
        price_decimals: 4,
        pip_size: 3,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'CMP/USD': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'CRV/USD': {
        type: 'Crypto',
        price_decimals: 4,
        pip_size: 3,
        ccy2_enabled: 1,
        netdania: 'CRVUSD',
        provider: 'cryptoxchange'
    },
    'DAI/USD': {
        type: 'Crypto',
        price_decimals: 4,
        pip_size: 3,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'DOG/USD': {
        type: 'Crypto',
        price_decimals: 6,
        pip_size: 5,
        ccy2_enabled: 1,
        netdania: 'DOGUSD',
        provider: 'netdania_rtc'
    },
    'DOT/USD': {
        type: 'Crypto',
        price_decimals: 4,
        pip_size: 3,
        ccy2_enabled: 1,
        netdania: 'DOTUSD',
        provider: 'netdania_rtc'
    },
    'ENJ/USD': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'ENJUSD',
        provider: 'cryptoxchange'
    },
    'EOS/AUD': {
        type: 'Crypto',
        price_decimals: 4,
        pip_size: 3,
        ccy2_enabled: 0,
        netdania: 'EOSAUD',
        provider: 'netdania_rtc'
    },
    'EOS/BTC': {
        type: 'Crypto',
        price_decimals: 8,
        pip_size: 7,
        ccy2_enabled: 0,
        netdania: 'EOSBTC',
        provider: 'netdania_rtc'
    },
    'EOS/EUR': {
        type: 'Crypto',
        price_decimals: 4,
        pip_size: 3,
        ccy2_enabled: 0,
        netdania: 'EOSEUR',
        provider: 'netdania_rtc'
    },
    'EOS/GBP': {
        type: 'Crypto',
        price_decimals: 4,
        pip_size: 3,
        ccy2_enabled: 0,
        netdania: 'EOSGBP',
        provider: 'netdania_rtc'
    },
    'EOS/JPY': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'EOS/USD': {
        type: 'Crypto',
        price_decimals: 4,
        pip_size: 3,
        ccy2_enabled: 1,
        netdania: 'EOSUSD',
        provider: 'netdania_rtc'
    },
    'EOS/UST': {
        type: 'Crypto',
        price_decimals: 4,
        pip_size: 3,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'ETH/AUD': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: 'ETHAUD',
        provider: 'netdania_rtc'
    },
    'ETH/BTC': {
        type: 'Crypto',
        price_decimals: 6,
        pip_size: 5,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'ETH/EUR': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: 'ETHEUR',
        provider: 'netdania_rtc'
    },
    'ETH/GBP': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: 'ETHGBP',
        provider: 'netdania_rtc'
    },
    'ETH/JPY': {
        type: 'Crypto',
        price_decimals: 0,
        pip_size: 0,
        ccy2_enabled: 0,
        netdania: 'ETHJPY',
        provider: 'netdania_rtc'
    },
    'ETH/USC': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'ETH/USD': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: 'ETHUSD',
        provider: 'netdania_rtc'
    },
    'ETH/UST': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'ETH/ZAR': {
        type: 'Crypto',
        price_decimals: 1,
        pip_size: 0,
        ccy2_enabled: 1,
        netdania: 'ETHZAR',
        provider: 'netdania_rtc'
    },
    'GLM/USD': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'KSM/USD': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: 'KSMUSD',
        provider: 'netdania_rtc'
    },
    'LNK/AUD': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: 'LNKAUD',
        provider: 'netdania_rtc'
    },
    'LNK/EUR': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: 'LNKEUR',
        provider: 'netdania_rtc'
    },
    'LNK/GBP': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: 'LNKGBP',
        provider: 'netdania_rtc'
    },
    'LNK/JPY': {
        type: 'Crypto',
        price_decimals: 1,
        pip_size: 0,
        ccy2_enabled: 0,
        netdania: 'LNKJPY',
        provider: 'netdania_rtc'
    },
    'LNK/USD': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: 'LNKUSD',
        provider: 'netdania_rtc'
    },
    'LNK/ZAR': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: 'LNKZAR',
        provider: 'netdania_rtc'
    },
    'LRC/USD': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'LTC/AUD': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: 'LTCAUD',
        provider: 'netdania_rtc'
    },
    'LTC/BTC': {
        type: 'Crypto',
        price_decimals: 8,
        pip_size: 7,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'LTC/EUR': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: 'LTCEUR',
        provider: 'netdania_rtc'
    },
    'LTC/GBP': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: 'LTCGBP',
        provider: 'netdania_rtc'
    },
    'LTC/JPY': {
        type: 'Crypto',
        price_decimals: 1,
        pip_size: 0,
        ccy2_enabled: 0,
        netdania: 'LTCJPY',
        provider: 'netdania_rtc'
    },
    'LTC/USC': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'LTC/USD': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: 'LTCUSD',
        provider: 'netdania_rtc'
    },
    'LTC/ZAR': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: 'LTCZAR',
        provider: 'netdania_rtc'
    },
    'MAN/USD': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'MAT/USD': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'MATUSD',
        provider: 'netdania_rtc'
    },
    'MKR/USD': {
        type: 'Crypto',
        price_decimals: 1,
        pip_size: 0,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'NER/USD': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'OMG/USD': {
        type: 'Crypto',
        price_decimals: 4,
        pip_size: 3,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'SND/USD': {
        type: 'Crypto',
        price_decimals: 4,
        pip_size: 3,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'SOL/USD': {
        type: 'Crypto',
        price_decimals: 4,
        pip_size: 3,
        ccy2_enabled: 1,
        netdania: 'SOLUSD',
        provider: 'netdania_rtc'
    },
    'SOL/EUR': {
        type: 'Crypto',
        price_decimals: 4,
        pip_size: 3,
        ccy2_enabled: 1,
        netdania: 'SOLEUR',
        provider: 'netdania_rtc'
    },
    'SOL/ZAR': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: 'SOLZAR',
        provider: 'netdania_rtc'
    },
    'SUS/USD': {
        type: 'Crypto',
        price_decimals: 4,
        pip_size: 3,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'TRX/USD': {
        type: 'Crypto',
        price_decimals: 6,
        pip_size: 5,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'UNI/USD': {
        type: 'Crypto',
        price_decimals: 4,
        pip_size: 3,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'UNI/ZAR': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'USC/AUD': {
        type: 'Crypto',
        price_decimals: 4,
        pip_size: 4,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'USC/CAD': {
        type: 'Crypto',
        price_decimals: 4,
        pip_size: 4,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'USC/CHF': {
        type: 'Crypto',
        price_decimals: 4,
        pip_size: 4,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'USC/EUR': {
        type: 'Crypto',
        price_decimals: 4,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'USC/GBP': {
        type: 'Crypto',
        price_decimals: 4,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'USC/JPY': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'USC/MXN': {
        type: 'Crypto',
        price_decimals: 4,
        pip_size: 4,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'USC/NZD': {
        type: 'Crypto',
        price_decimals: 4,
        pip_size: 4,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'USC/SGD': {
        type: 'Crypto',
        price_decimals: 4,
        pip_size: 4,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'USC/USD': {
        type: 'Crypto',
        price_decimals: 4,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'USC/ZAR': {
        type: 'Crypto',
        price_decimals: 4,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'UST/AUD': {
        type: 'Crypto',
        price_decimals: 4,
        pip_size: 4,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'UST/EUR': {
        type: 'Crypto',
        price_decimals: 4,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'UST/GBP': {
        type: 'Crypto',
        price_decimals: 4,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'UST/JPY': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'UST/USC': {
        type: 'Crypto',
        price_decimals: 4,
        pip_size: 4,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'UST/USD': {
        type: 'Crypto',
        price_decimals: 4,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'UST/ZAR': {
        type: 'Crypto',
        price_decimals: 4,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'XAU/BTC': {
        type: 'Crypto',
        price_decimals: 6,
        pip_size: 5,
        ccy2_enabled: 1,
        netdania: 'XAUBTC',
        provider: 'netdania_rtc'
    },
    'XLM/USD': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'XRP/AUD': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'XRPAUD',
        provider: 'netdania_rtc'
    },
    'XRP/EUR': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'XRPEUR',
        provider: 'netdania_rtc'
    },
    'XRP/GBP': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'XRPGBP',
        provider: 'netdania_rtc'
    },
    'XRP/JPY': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 3,
        ccy2_enabled: 1,
        netdania: 'XRPJPY',
        provider: 'netdania_rtc'
    },
    'XRP/USD': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'XRPUSD',
        provider: 'netdania_rtc'
    },
    'XRP/ZAR': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'XRPZAR',
        provider: 'netdania_rtc'
    },
    'XTZ/USD': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'AUD/CAD': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'AUDCAD',
        provider: 'netdania_rtc'
    },
    'AUD/CHF': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'AUDCHF',
        provider: 'netdania_rtc'
    },
    'AUD/JPY': {
        type: 'FX',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: 'AUDJPY',
        provider: 'netdania_rtc'
    },
    'AUD/NZD': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'AUDNZD',
        provider: 'netdania_rtc'
    },
    'AUD/USD': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'AUDUSD',
        provider: 'netdania_rtc'
    },
    'CAD/CHF': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'CADCHF',
        provider: 'netdania_rtc'
    },
    'CAD/JPY': {
        type: 'FX',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: 'CADJPY',
        provider: 'netdania_rtc'
    },
    'CHF/JPY': {
        type: 'FX',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: 'CHFJPY',
        provider: 'netdania_rtc'
    },
    'EUR/AUD': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'EURAUD',
        provider: 'netdania_rtc'
    },
    'EUR/CAD': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'EURCAD',
        provider: 'netdania_rtc'
    },
    'EUR/CHF': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'EURCHF',
        provider: 'netdania_rtc'
    },
    'EUR/CZK': {
        type: 'FX',
        price_decimals: 4,
        pip_size: 3,
        ccy2_enabled: 1,
        netdania: 'EURCZK',
        provider: 'netdania_rtc'
    },
    'EUR/DKK': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'EURDKK',
        provider: 'netdania_rtc'
    },
    'EUR/GBP': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'EURGBP',
        provider: 'netdania_rtc'
    },
    'EUR/HKD': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'EURHKD',
        provider: 'netdania_rtc'
    },
    'EUR/HUF': {
        type: 'FX',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: 'EURHUF',
        provider: 'netdania_rtc'
    },
    'EUR/JPY': {
        type: 'FX',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: 'EURJPY',
        provider: 'netdania_rtc'
    },
    'EUR/MXN': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'EURMXN',
        provider: 'netdania_rtc'
    },
    'EUR/NOK': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'EURNOK',
        provider: 'netdania_rtc'
    },
    'EUR/NZD': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'EURNZD',
        provider: 'netdania_rtc'
    },
    'EUR/PLN': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'EURPLN',
        provider: 'netdania_rtc'
    },
    'EUR/SEK': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'EURSEK',
        provider: 'netdania_rtc'
    },
    'EUR/SGD': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'EURSGD',
        provider: 'netdania_rtc'
    },
    'EUR/TRY': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'EURTRY',
        provider: 'netdania_rtc'
    },
    'EUR/USD': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'EURUSD',
        provider: 'netdania_rtc'
    },
    'EUR/ZAR': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'EURZAR',
        provider: 'netdania_rtc'
    },
    'GBP/AUD': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'GBPAUD',
        provider: 'netdania_rtc'
    },
    'GBP/CAD': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'GBPCAD',
        provider: 'netdania_rtc'
    },
    'GBP/CHF': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'GBPCHF',
        provider: 'netdania_rtc'
    },
    'GBP/CZK': {
        type: 'FX',
        price_decimals: 4,
        pip_size: 3,
        ccy2_enabled: 1,
        netdania: 'GBPCZK',
        provider: 'netdania_rtc'
    },
    'GBP/DKK': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'GBPDKK',
        provider: 'netdania_rtc'
    },
    'GBP/HKD': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'GBPHKD',
        provider: 'netdania_rtc'
    },
    'GBP/HUF': {
        type: 'FX',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: 'GBPHUF',
        provider: 'netdania_rtc'
    },
    'GBP/JPY': {
        type: 'FX',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: 'GBPJPY',
        provider: 'netdania_rtc'
    },
    'GBP/MXN': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'GBPMXN',
        provider: 'netdania_rtc'
    },
    'GBP/NOK': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'GBPNOK',
        provider: 'netdania_rtc'
    },
    'GBP/NZD': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'GBPNZD',
        provider: 'netdania_rtc'
    },
    'GBP/PLN': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'GBPPLN',
        provider: 'netdania_rtc'
    },
    'GBP/SEK': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'GBPSEK',
        provider: 'netdania_rtc'
    },
    'GBP/SGD': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'GBPSGD',
        provider: 'netdania_rtc'
    },
    'GBP/TRY': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'GBPTRY',
        provider: 'netdania_rtc'
    },
    'GBP/USD': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'GBPUSD',
        provider: 'netdania_rtc'
    },
    'GBP/ZAR': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'GBPZAR',
        provider: 'netdania_rtc'
    },
    'NZD/CAD': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'NZDCAD',
        provider: 'netdania_rtc'
    },
    'NZD/CHF': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'NZDCHF',
        provider: 'netdania_rtc'
    },
    'NZD/JPY': {
        type: 'FX',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: 'NZDJPY',
        provider: 'netdania_rtc'
    },
    'NZD/SGD': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'NZDSGD',
        provider: 'netdania_rtc'
    },
    'NZD/USD': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'NZDUSD',
        provider: 'netdania_rtc'
    },
    'USD/AED': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'USD/CAD': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'USDCAD',
        provider: 'netdania_rtc'
    },
    'USD/CHF': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'USDCHF',
        provider: 'netdania_rtc'
    },
    'USD/CNH': {
        type: 'FX',
        price_decimals: 4,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'USDCNH',
        provider: 'netdania_rtc'
    },
    'USD/CZK': {
        type: 'FX',
        price_decimals: 4,
        pip_size: 3,
        ccy2_enabled: 1,
        netdania: 'USDCZK',
        provider: 'netdania_rtc'
    },
    'USD/DKK': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'USDDKK',
        provider: 'netdania_rtc'
    },
    'USD/HKD': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'USDHKD',
        provider: 'netdania_rtc'
    },
    'USD/HUF': {
        type: 'FX',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: 'USDHUF',
        provider: 'netdania_rtc'
    },
    'USD/ILS': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'USDILS',
        provider: 'netdania_rtc'
    },
    'USD/JPY': {
        type: 'FX',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: 'USDJPY',
        provider: 'netdania_rtc'
    },
    'USD/MXN': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'USDMXN',
        provider: 'netdania_rtc'
    },
    'USD/NOK': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'USDNOK',
        provider: 'netdania_rtc'
    },
    'USD/PLN': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'USDPLN',
        provider: 'netdania_rtc'
    },
    'USD/SEK': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'USDSEK',
        provider: 'netdania_rtc'
    },
    'USD/SGD': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'USDSGD',
        provider: 'netdania_rtc'
    },
    'USD/TRY': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'USDTRY',
        provider: 'netdania_rtc'
    },
    'USD/ZAR': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: 'USDZAR',
        provider: 'netdania_rtc'
    },
    'XAG/AUD': {
        type: 'FX',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: 'XAGAUD',
        provider: 'netdania_rtc'
    },
    'XAG/USD': {
        type: 'FX',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: 'XAGUSD',
        provider: 'netdania_rtc'
    },
    'XAU/AUD': {
        type: 'FX',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: 'XAUAUD',
        provider: 'netdania_rtc'
    },
    'XAU/EUR': {
        type: 'FX',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: 'XAUEUR',
        provider: 'netdania_rtc'
    },
    'XAU/USD': {
        type: 'FX',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: 'XAUUSD',
        provider: 'netdania_rtc'
    },
    'XPD/USD': {
        type: 'FX',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: 'XPDUSD',
        provider: 'netdania_rtc'
    },
    'XPT/USD': {
        type: 'FX',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: 'XPTUSD',
        provider: 'netdania_rtc'
    },
    'BCH/CAD': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BCH/CHF': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BCH/CNH': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BCH/CZK': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BCH/DKK': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BCH/HKD': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BCH/HUF': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BCH/ILS': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BCH/LTC': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 3,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BCH/MXN': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BCH/NOK': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BCH/NZD': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BCH/PLN': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BCH/RON': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BCH/RUB': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BCH/SEK': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BCH/SGD': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BCH/TRY': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BCH/UST': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'BCH/XAG': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BCH/XAU': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BCH/XBR': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BCH/XNG': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BCH/XPD': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BCH/XPT': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BCH/XRP': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BCH/XTI': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BTC/BCH': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'ETH/BCH': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BTC/CAD': {
        type: 'Crypto',
        price_decimals: 1,
        pip_size: 0,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BTC/CHF': {
        type: 'Crypto',
        price_decimals: 1,
        pip_size: 0,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BTC/CNH': {
        type: 'Crypto',
        price_decimals: 1,
        pip_size: 0,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BTC/CZK': {
        type: 'Crypto',
        price_decimals: 1,
        pip_size: 0,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BTC/DKK': {
        type: 'Crypto',
        price_decimals: 1,
        pip_size: 0,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BTC/ETH': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 0,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BTC/HKD': {
        type: 'Crypto',
        price_decimals: 1,
        pip_size: 0,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BTC/HUF': {
        type: 'Crypto',
        price_decimals: 1,
        pip_size: 0,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BTC/ILS': {
        type: 'Crypto',
        price_decimals: 1,
        pip_size: 0,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BTC/LTC': {
        type: 'Crypto',
        price_decimals: 1,
        pip_size: 0,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BTC/MXN': {
        type: 'Crypto',
        price_decimals: 1,
        pip_size: 0,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BTC/NOK': {
        type: 'Crypto',
        price_decimals: 1,
        pip_size: 0,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BTC/NZD': {
        type: 'Crypto',
        price_decimals: 1,
        pip_size: 0,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BTC/PLN': {
        type: 'Crypto',
        price_decimals: 1,
        pip_size: 0,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BTC/RON': {
        type: 'Crypto',
        price_decimals: 1,
        pip_size: 0,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BTC/RUB': {
        type: 'Crypto',
        price_decimals: 1,
        pip_size: 0,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BTC/SEK': {
        type: 'Crypto',
        price_decimals: 1,
        pip_size: 0,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BTC/TRY': {
        type: 'Crypto',
        price_decimals: 1,
        pip_size: 0,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BTC/XAG': {
        type: 'Crypto',
        price_decimals: 1,
        pip_size: 0,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BTC/XAU': {
        type: 'Crypto',
        price_decimals: 1,
        pip_size: 0,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BTC/XBR': {
        type: 'Crypto',
        price_decimals: 1,
        pip_size: 0,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BTC/XNG': {
        type: 'Crypto',
        price_decimals: 1,
        pip_size: 0,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BTC/XPD': {
        type: 'Crypto',
        price_decimals: 1,
        pip_size: 0,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BTC/XPT': {
        type: 'Crypto',
        price_decimals: 1,
        pip_size: 0,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BTC/XRP': {
        type: 'Crypto',
        price_decimals: 1,
        pip_size: 0,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BTC/XTI': {
        type: 'Crypto',
        price_decimals: 1,
        pip_size: 0,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'XRP/BTC': {
        type: 'Crypto',
        price_decimals: 8,
        pip_size: 7,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'ETH/CAD': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'ETH/CHF': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'ETH/CNH': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'ETH/CZK': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'ETH/DKK': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'ETH/HKD': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'ETH/HUF': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'ETH/ILS': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'ETH/LTC': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'ETH/MXN': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'ETH/NOK': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'ETH/NZD': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'ETH/PLN': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'ETH/RON': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'ETH/RUB': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'ETH/SEK': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'ETH/SGD': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'ETH/TRY': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'ETH/XAG': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'ETH/XAU': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'ETH/XBR': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'ETH/XNG': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'ETH/XPD': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'ETH/XPT': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'ETH/XRP': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'ETH/XTI': {
        type: 'Crypto',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'XRP/CAD': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'XRP/CHF': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'XRP/CNH': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'XRP/CZK': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'XRP/DKK': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'XRP/HKD': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'XRP/HUF': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'XRP/ILS': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'XRP/LTC': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'XRP/MXN': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'XRP/NOK': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'XRP/NZD': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'XRP/PLN': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'XRP/RON': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'XRP/RUB': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'XRP/SEK': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'XRP/SGD': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'XRP/TRY': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'XRP/USC': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'XRP/UST': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'XRP/XAG': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'XRP/XAU': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'XRP/XBR': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'XRP/XNG': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'XRP/XPD': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'XRP/XPT': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'XRP/XTI': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'EOS/CAD': {
        type: 'Crypto',
        price_decimals: 4,
        pip_size: 3,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'EOS/CHF': {
        type: 'Crypto',
        price_decimals: 4,
        pip_size: 3,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'EOS/SGD': {
        type: 'Crypto',
        price_decimals: 4,
        pip_size: 3,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'EOS/USC': {
        type: 'Crypto',
        price_decimals: 4,
        pip_size: 3,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'LTC/CAD': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'LTC/CHF': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'LTC/CNH': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'LTC/CZK': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'LTC/DKK': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'LTC/HKD': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'LTC/HUF': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'LTC/ILS': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'LTC/MXN': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'LTC/NOK': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'LTC/NZD': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'LTC/PLN': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'LTC/RON': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'LTC/RUB': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'LTC/SEK': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'LTC/SGD': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'LTC/TRY': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'LTC/UST': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'LTC/XAG': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'LTC/XAU': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'LTC/XBR': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'LTC/XNG': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'LTC/XPD': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'LTC/XPT': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'LTC/XTI': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'UST/CAD': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'UST/CHF': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'UST/SGD': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'CNH/JPY': {
        type: 'FX',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'EUR/RON': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'EUR/RUB': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'FTT/USD': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'LNK/CAD': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'LNK/CHF': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'LNK/SGD': {
        type: 'Crypto',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'MXN/JPY': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'NOK/SEK': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'TRY/JPY': {
        type: 'FX',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'USD/RON': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'USD/RUB': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'ZAR/JPY': {
        type: 'FX',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'SHB/USD': {
        type: 'Crypto',
        price_decimals: 9,
        pip_size: 9,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'AXI/AUD': {
        type: 'Index',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'STX/EUR': {
        type: 'Index',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'FRI/EUR': {
        type: 'Index',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'GEM/EUR': {
        type: 'Index',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'UKI/GBP': {
        type: 'Index',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'HSI/HKD': {
        type: 'Index',
        price_decimals: 2,
        pip_size: 0,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'JPU/JPY': {
        type: 'Index',
        price_decimals: 2,
        pip_size: 0,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'SGP/SGD': {
        type: 'Index',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'DJI/USD': {
        type: 'Index',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'SPM/USD': {
        type: 'Index',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'NDM/USD': {
        type: 'Index',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'CNI/USD': {
        type: 'Index',
        price_decimals: 2,
        pip_size: 0,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'RSI/USD': {
        type: 'Index',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'SPL/USD': {
        type: 'Index',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'TWN/USD': {
        type: 'Index',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'UKO/USD': {
        type: 'Commodity',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'USO/USD': {
        type: 'Commodity',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'GRT/USD': {
        type: 'Crypto',
        price_decimals: 6,
        pip_size: 5,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'GAL/USD': {
        type: 'Crypto',
        price_decimals: 6,
        pip_size: 5,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'FTM/USD': {
        type: 'Crypto',
        price_decimals: 6,
        pip_size: 5,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'APE/USD': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'ALG/USD': {
        type: 'Crypto',
        price_decimals: 6,
        pip_size: 5,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'USI/USD': {
        type: 'Index',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'WBC/USD': {
        type: 'Crypto',
        price_decimals: 1,
        pip_size: 0,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'BTC/AED': {
        type: 'Crypto',
        price_decimals: 0,
        pip_size: 0,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'USC/AED': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'ETH/AED': {
        type: 'Crypto',
        price_decimals: 0,
        pip_size: 0,
        ccy2_enabled: 1,
        netdania: '',
        provider: ''
    },
    'GAS/USD': {
        type: 'Commodity',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'VIX/USD': {
        type: 'Index',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'NED/EUR': {
        type: 'Index',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'ITA/EUR': {
        type: 'Index',
        price_decimals: 1,
        pip_size: 0,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'SMI/CHF': {
        type: 'Index',
        price_decimals: 1,
        pip_size: 0,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'IBX/EUR': {
        type: 'Index',
        price_decimals: 1,
        pip_size: 0,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'GLD/USD': {
        type: 'Commodity',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'NAT/USD': {
        type: 'Commodity',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'COC/USD': {
        type: 'Commodity',
        price_decimals: 1,
        pip_size: 0,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'FNG/USD': {
        type: 'Index',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'CPP/USD': {
        type: 'Commodity',
        price_decimals: 4,
        pip_size: 4,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'COF/USD': {
        type: 'Commodity',
        price_decimals: 4,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'CTL/USD': {
        type: 'Commodity',
        price_decimals: 5,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'PAL/USD': {
        type: 'Commodity',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'PLA/USD': {
        type: 'Commodity',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'SWD/SEK': {
        type: 'Index',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'SLV/USD': {
        type: 'Commodity',
        price_decimals: 3,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'COR/USD': {
        type: 'Commodity',
        price_decimals: 4,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'SOY/USD': {
        type: 'Commodity',
        price_decimals: 4,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'WHT/USD': {
        type: 'Commodity',
        price_decimals: 4,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'TPX/JPY': {
        type: 'Index',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'SUG/USD': {
        type: 'Commodity',
        price_decimals: 4,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'HSK/HKD': {
        type: 'Index',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'INI/USD': {
        type: 'Index',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'HSH/HKD': {
        type: 'Index',
        price_decimals: 2,
        pip_size: 2,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'BNK/USD': {
        type: 'Crypto',
        price_decimals: 8,
        pip_size: 7,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'MOR/USD': {
        type: 'Crypto',
        price_decimals: 4,
        pip_size: 4,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'PEP/USD': {
        type: 'Crypto',
        price_decimals: 8,
        pip_size: 7,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'WIF/USD': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'STR/USD': {
        type: 'Crypto',
        price_decimals: 5,
        pip_size: 4,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    },
    'XDC/USD': {
        type: 'Crypto',
        price_decimals: 6,
        pip_size: 5,
        ccy2_enabled: 0,
        netdania: '',
        provider: ''
    }
    // BRE/USD
    // INI/USD
    // JPI/JPY
    // WTI/USD
};

export default instrumentConfig;
