import { useAppSelector } from '@/state/hooks';
import { selectAggregatedBalance, selectBalanceStatus, selectNopBalance } from '@/state/reducers/balanceSlice';
import { useMemo } from 'react';
import OldBalance from './OldBalance';

interface BalancesProps {}

export default function Balances(props: BalancesProps) {
    const status = useAppSelector(selectBalanceStatus);
    const nopBalance = useAppSelector(selectNopBalance);
    const celerBalance = useAppSelector(selectAggregatedBalance);

    const balances: any = useMemo(() => {
        return celerBalance;
    }, [celerBalance, nopBalance]);

    return (
        <div className="flex flex-col h-full text-gray-200">
            <div className="flex justify-between p-2 pb-0 text-sm px-3">
                <div className="text-left flex-[1_1_0]">Currency</div>
                <div className="flex flex-[2_1_0] relative justify-center gap-2">
                    <div className="text-right flex-[1_1_0]">Balance</div>
                </div>
            </div>
            {status === 'OPEN' && (
                <div className="w-full flex flex-col relative self-stretch grow basis-full overflow-y-auto px-2 py-1">
                    {balances.map((balance, index) => (
                        <OldBalance key={index} balance={balance} isNewlyAdded={false} />
                    ))}
                </div>
            )}
            {status === 'CLOSED' && (
                <div className="flex flex-col gap-2 p-2 justify-center items-center h-full text-sm text-center text-neutral-400">
                    <div className="italic">Unable to fetch live balances</div>
                    <div className="italic">Please reach out to support</div>
                </div>
            )}
        </div>
    );
}
